<template>
    <div class="discourse groupDetail">
        <van-pull-refresh class="discourse-push" v-model="searchLoading" @refresh="onRefresh">
            <div>
                <van-search
                    v-model="keyword"
                    show-action
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                    @input="handleInput"
                    >
                    <template #action>
                        <div @click="onClear">
                            <van-icon name="replay" />
                        </div>
                    </template>
                </van-search>
            </div>
            <van-collapse v-model="activeNames">
                <van-collapse-item v-for="item in list" :key="item.id" :disabled="item.books.length == 0" :label="`${item.target_time}`" :title="`${item.keywords}`" :name="item.id">
                    <div slot="value"><van-badge color="#00cca2" :content="item.books_count" /></div>
                    <ul>
                        <li @click="toClick(it)" :class="j !=item.books.length -1?'van-hairline--bottom groupDetail-item':'groupDetail-item'" v-for="(it,j) in item.books" :key="it.id">
                            <div class="groupDetail-item-avatar">
                                <img v-if="it.avatar" :src="it.avatar">
                                <img v-else src="@/assets/avatar-default.svg">
                            </div>
                            <div class="groupDetail-item-content">
                                <div>
                                    <span>{{it.remark}} ({{it.name}}) </span>
                                </div>
                                <div class="groupDetail-item-tag">
                                    <!-- <img v-if="it.gender == 2" style="width: 12px;margin-right:5px" src="@/assets/woman.png">
                                    <img v-if="it.gender == 1" style="width: 12px;margin-right:5px" src="@/assets/man.png"> -->
                                    <span class="groupDetail-item-time">
                                        <span margin-right:5px>发送时间:{{it.created_at}}</span>
                                    </span>
                                </div>
                            </div>
                            <!-- <div @click="toBuy(item)" class="" v-for="(item,index) in list" :key="index">
                            </div> -->
                        </li>
                    </ul>
                </van-collapse-item>
                <van-empty v-if="list.length == 0" description="暂无相关数据~" />
            </van-collapse>
        </van-pull-refresh>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { Search,Collapse, CollapseItem,Icon,Toast,PullRefresh,Badge,Empty } from 'vant'
    import { wxWorkOpenUserProfile } from '@/common/wx'
    import Loadings from '@/components/loading'
    import { wxWorkSend } from '@/common/wx'
    import dayjs from 'dayjs'
    export default {
        name:'bookEventsIndex',
        data() {
            return {
                loading:false,
                searchLoading:false,
                keyword:'',
                activeNames:[],
                list:[],
                newsList:[]
            }
        },
        components: {
            [Icon.name]:Icon,
            [Empty.name]:Empty,
            loadings:Loadings,
            [Badge.name]:Badge,
            [Search.name]:Search,
            [Collapse.name]:Collapse,
            [PullRefresh.name]:PullRefresh,
            [CollapseItem.name]:CollapseItem,
        },
        created () {
            this.getList()
        },
        methods: {
            getList(){
                this.loading = true
                this.$api.book_events_index({keyword:this.keyword})
                .then(res => {
                    this.list = res.items
                    if(this.keyword){
                        let arr = []
                        this.list.forEach(item=>{
                            arr.push(item.id)
                        })
                        this.activeNames = arr
                    }else{
                        this.activeNames = []
                    }
                    this.loading = false
                    this.searchLoading = false
                })
                .catch(err=>{
                    this.loading = false
                    this.searchLoading = false
                })
            },
            toClick(it){
                let obj = {
                    type:2,
                    userid:it.msg_from
                }
                wxWorkOpenUserProfile(obj)
                .catch(err=>{
                    console.log(err)
                })
            },
            onSearch() {
                this.getList()
            },
            handleInput(){
                this.getList()
            },
            onClear(){
                this.keyword = ''
                this.getList()
            },
            onRefresh(){
                this.getList()
            }
        },
    }
</script>

<style lang="less" scoped>
    .discourse{
        height: 100vh;
        background: #fff;
        &-push{
            min-height: 100vh;
        }
        &-nav{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img{
                width: 24px;
                margin-right: 10px;
            }
            h1{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
        &-news{
            white-space: nowrap;
            overflow-x: scroll;
            &-item{
                display: inline-block;
                margin: 10px;
            }
            &-content{
                display: flex;
                box-shadow: 0px 0px 3px #d9d9d9;
                padding: 10px 5px;
                border-radius: 4px;
                &-img{
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    img{
                        width: 28px;
                        height: 28px;
                    }
                }
                &-desc{
                    font-size: 12px;
                    color: #999;
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }
        }
        &-text{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &-content{
                margin-left: 34px;
            }
        }
        &-image{
            display: flex;
            align-items: center;
            img{
                height: 64px;
                margin-right: 10px;
                margin-left: 34px;
                border-radius: 2px;
            }
            div{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
        &-video{
            display: flex;
            align-items: center;
            &-box{
                position: relative;
                margin-right: 10px;
                margin-left: 34px;
                img{
                    height: 64px;
                    border-radius: 2px;
                }
            }
            &-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -7px;
                margin-top: -7px;
                color: #fff;
            }
            &-name{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
            
        }
        &-file{
            display: flex;
            align-items: center;
            img{
                width: 24px;
                margin-right: 5px;
                margin-left: 34px;
            }
            div{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
    }
</style>
<style lang="less" scoped>
    .style-box() {
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
    }
    .groupDetail{
        min-height: 100vh;
        background: #fff;
        &-bg{
            height: 8px;
            background: #f8f8f8;
        }
        &-nav{
            display: flex;
            align-items: center;
            padding: 10px 0 10px 10px;
            img{
                margin-right: 10px;
                border-radius:2px;
            }
        }
        &-col{
            padding: 5px 0;
        }
        &-num{
            text-align: center;
            padding: 5px 0;
        }
        &-time{
            padding: 10px 0 10px 10px;
        }
        &-sex{
            display: flex;
            align-items: center;
            margin-top: 8px;
            img{
                width: 16px;
                margin-right: 5px;
            }
            div{
                display: flex;
                align-items: center;
                margin-left: 10px;
            }
            &-item{
               display: flex;
               align-items: center; 
               margin-right: 20px;
            }

        }
        &-tab{
            height: 44px;
            border-bottom: 1px solid #ebedf0;
        }
        &-sort{
            height: 44px;
            position: relative;
            display: flex;
            align-items: center;
            &-icon{
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
        &-item{
            display: flex;
            padding: 10px 0 10px 10px;
            position: relative;
            &-avatar{
                margin-right: 10px;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                }
            }
            &-tag{
                display: flex;
                align-items: center;
                margin-top: 5px;
            }
            &-time{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #999;
            }
            &-available-time{
                display: inline-block;
                width: 90px;
                margin-right: 5px;
            }
            &-more{
                position: absolute;
                right: 10px;
                padding-bottom: 20px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-notice{
                margin-left: 5px;
            }
        }
    }
    .userDetail{
        &-nav{
            padding: 10px 0 10px 10px;
            &-box{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-avatar{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 10px;
                margin-bottom: 5px;
            }
            &-name{
                width: 50px;
                height: 22px;
                background: rgba(0,0,0,.2);
                color: #fff;
                line-height: 22px;
                border-radius: 11px;
                padding: 0 5px;
                text-align: center;
                font-size: 12px;
            }
        }
        &-info{
            padding: 10px 0 10px 10px;
            font-size: 12px;
        }
        &-addB{
            padding: 0 15px 15px 15px;
        }
        &-add{
            position: relative;
            display: flex;
            align-items: center;
            font-size: 12px;
            background: #fff;
            border-radius: 8px;
            padding: 10px;
            img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #00cca2;
            }
            &-group{
                position: absolute;
                right: 10px;
                display: flex;
                align-items: center;
            }

        }
        &-popup{
            padding: 10px;
            &-item{
                display: flex;
                padding: 10px 0;
                position: relative;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                    margin-right: 8px;
                }
            }
            &-empty{
                text-align: center;
                padding: 10px 0;
                font-size: 12px;
                color: #999;
            }
            &-group-owner{
                font-size: 12px;
                color: #999;
            }
            &-copy{
                position: absolute;
                right: 10px;
                color: #999;
            }
        }
        &-binging{
            color: #00cca2;
        }
    }
    .header {
        box-sizing: border-box;
        width: 100%;
        height: 227px;
        color: #fff;
        padding: 0 15px;
        // background-color: #fe4140;
        padding-top: 20px;
        background: @linear-gradient-color;
        background-image: url('~@/assets/user_bg.png');
        background-size: 100% 100%;
        &-media {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        }
        &-title {
        font-size: 18px;
        }
        &-setting {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 24px;
        }
    }
    .header-block{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        overflow-x: auto;
        position: relative;
        &-item{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        }
        &-items{
        position: relative;
        margin-right: 10px;
        flex-shrink: 0;
        }
        &-media {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
        &-avatar{
        width: 58px;
        height: 58px;
        overflow: hidden;
        border-radius: 50%;
        }
        &-medias {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        }
        &-avatars{
        width: 38px;
        height: 38px;
        overflow: hidden;
        border-radius: 50%;
        }
        &-bg{
        position: absolute;
        top: 1px;
        left: 1px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: rgba(0,0,0,.5);
        }
        &-title {
        text-align: center;
        font-size: 12px;
        background: rgba(0,0,0,.3);
        border-radius: 10px;
        margin-top: 3px;
        height: 21px;
        span{
            display: inline-block;
            width: 50px;
            text-align: center;
            line-height: 1.8;
            color: #fff;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            word-wrap:normal;
        }
        }
        &-titles {
        background: rgba(0,0,0,.3);
        border-radius: 10px;
        margin-top: 3px;
        text-align: center;
        height: 18px;
        span{
            display: inline-block;
            width: 30px;
            text-align: center;
            font-size: 10px;
            line-height: 1.8;
            color: #fff;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            word-wrap:normal;
        }
        }
        &-change{
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 2px;
        }
    }
    .main {
        margin-top: -140px;
    }
    .group {
        .style-box();
        margin: 0 15px 15px 15px;
        &-inner{
        padding:10px 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        }
        &-item{
        flex: 1;
        text-align: center;
        }
    }
    .balance-group {
        .style-box();
        .van-row {
        text-align: center;
        }
        .van-col {
        text-align: center;
        }
        &-title {
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-bottom: 5px;
        }
        &-txt {
        font-size: 12px;
        color: #999;
        }
    }
</style>